<template>
  <div class="product-page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header d-flex py-3 align-center">
        <span class="page-title">{{ $t('Bundles') }}</span>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-center">
          <v-spacer />
          <v-btn color="primary" @click="$router.push({ name: 'website.apps.bundle.create' })"
            ><i class="fas fa-plus mr-1"></i>{{ $t('Create bundles') }}</v-btn
          >
        </v-col>
      </v-col>
      <v-col cols="12" class="d-flex">
        <span
          >Offer customers to buy in a bulk of products with your attractive discount.
          <a href="#" class="color-main">See how it looks </a>
        </span>
      </v-col>
    </v-row>
    <Offer />
  </div>
</template>

<script>
import Offer from './components/Offer';
export default {
  components: {
    Offer,
  },
};
</script>
